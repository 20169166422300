

const Call = () => {
    return (
        <section
        className="fixed right-[10px] bottom-[10px] z-50" 
        >
        <a href="tel:07083364782">
        <button
            className="bg-[#e91e63] h-[60px] w-[60px] rounded-[50%] flex flex-col items-center justify-center"
            >
            <i class='bx bx-phone text-[30px] text-[white]'></i>
            </button>
        </a>

        </section>
    )
}

export default Call