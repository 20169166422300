import { NavLink, Link } from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";
import "./home.css";
function Header({ isOpen }) {
  const [showSecondNav, setShowSecondNav] = useState(false);

  const switcher = () => {
    setShowSecondNav(!showSecondNav);
  };

  return (
    <>

      <nav
        className=" flex-row items-center bg-transparent bg-[white] border-solid border-[1px]
        border-[grey] hidden lg:flex
         z-50 justify-between h-[70px] px-[10px] font-[200] overflow-y-hidden"
      >
      <Link
      to="/"
      >
      <img 
      loading="lazy"
        src="/images/companyLogo.png"
        className="w-[47px] h-[40px] mb-[0]"
    />
      </Link>
    <div
    className="flex flex-row items-center w-[50%] justify-between"
    >
    <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "flex flex-col mr-[60px] w-[25%] rounded-[8px] justify-center py-[10px] items-center text-[#e91e63] font-[900] hover:bg-[#e91e63] hover:text-[white]"
              : "hover:bg-[#e91e63] mr-[60px] w-[25%] h-[40px] flex flex-row items-center justify-center transition duration-300 ease-in-out rounded-[8px] hover:text-[white] "
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/contactus"
          className={({ isActive }) =>
            isActive
              ? "flex flex-col mr-[60px] w-[25%] rounded-[8px] justify-center py-[10px] items-center text-[#e91e63] font-[900] hover:bg-[#e91e63] hover:text-[white]"
              : "hover:bg-[#e91e63] mr-[60px] w-[25%] h-[40px] flex flex-row items-center justify-center transition duration-300 ease-in-out rounded-[8px] hover:text-[white] "
          }
        >
          Contact Us
        </NavLink>
        <NavLink
          to="/ourservices"
          className={({ isActive }) =>
            isActive
              ? "flex flex-col mr-[60px] w-[25%] rounded-[8px] justify-center py-[10px] items-center text-[#e91e63] font-[900] hover:bg-[#e91e63] hover:text-[white]"
              : "hover:bg-[#e91e63] mr-[60px] w-[25%] h-[40px] flex flex-row items-center justify-center transition duration-300 ease-in-out rounded-[8px] hover:text-[white] "
          }
        >
          Our Services
        </NavLink>
        <NavLink
          to="/aboutus"
          className={({ isActive }) =>
            isActive
              ? "flex flex-col rounded-[8px] mr-[60px] w-[25%] justify-center py-[10px] items-center text-[#e91e63] font-[900] hover:bg-[#e91e63] hover:text-[white]"
              : "hover:bg-[#e91e63] h-[40px] mr-[60px] w-[25%] flex flex-row items-center justify-center transition duration-300 ease-in-out rounded-[8px] hover:text-[white] "
          }
        >
          About Us
        </NavLink>
    </div>

      </nav>

      <div className="lg:hidden flex flex-row items-center justify-between px-[10px]">
      <Link
      to="/"
      >
      <img 
      loading="lazy"
        src="/images/companyLogo.png"
        className="w-[47px] h-[40px] mb-[0]"
    />
      </Link>

        <button
          onClick={switcher}
          className="flex flex-col items-end justify-end w-[50px]"
        >
          {showSecondNav ? (
            <div className="mb-[0px] pr-[5px] py-[10px] transition-[0.5s]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="2em"
                viewBox="0 0 384 512"
              >
                <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
              </svg>
            </div>
          ) : (
            <div className="mb-[0px] pr-[5px] py-[10px] ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="2em"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </div>
          )}
        </button>

        <motion.ul
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className={`absolute top-[50px] text-center z-50 flex h-[350px] w-full flex-col items-start justify-start leading-[80px] bg-[white] px-[12px] py-[12px] transition-[0.3s] ${
            showSecondNav ? "left-[0px]" : "left-[-1400px]"
          }`}
        >
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "w-full text-[#e91e63] font-[900] hover:bg-[#e91e63] hover:text-[white] rounded-[8px]"
                : "hover:bg-[#e91e63] hover:rounded-[8px] w-full hover:text-[white] "
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/contactus"
            className={({ isActive }) =>
              isActive
                ? "w-full text-[#e91e63] font-[900] hover:bg-[#e91e63] hover:text-[white] rounded-[8px]"
                : "hover:bg-[#e91e63] hover:rounded-[8px] w-full hover:text-[white] "
            }
          >
            
            Contact Us
          </NavLink>
          <NavLink
            to="/ourservices"
            className={({ isActive }) =>
              isActive
                ? "w-full text-[#e91e63] font-[900] hover:bg-[#e91e63] hover:text-[white] rounded-[8px]"
                : "hover:bg-[#e91e63] hover:rounded-[8px] w-full hover:text-[white] "
            }
          >
            Our Services
          </NavLink>
          <NavLink
            to="/aboutus"
            className={({ isActive }) =>
              isActive
                ? "w-full text-[#e91e63] font-[900] hover:bg-[#e91e63] hover:text-[white] rounded-[8px]"
                : "hover:bg-[#e91e63] hover:rounded-[8px] w-full hover:text-[white] "
            }
          >
            About Us
          </NavLink>
        </motion.ul>
      </div>
    </>
  );
}

export default Header;
