import "./home.css";
import Header from "./Header";
import { motion } from "framer-motion";
import { useState } from "react";
import Call from "./Call";
import { Link } from "react-router-dom";

const NoFound = () => {
    return(
        <motion.ul 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
    >
    
    <Call />
      <body className=" flex flex-col items-center justify-center"
      
      >
        <div className="bodyStarter w-full pt-[50px] pb-[80px] h-[100vh] z-48 text-[white] flex flex-col items-center justify-center">
          <h1 className="text-[40px] lg:text-[50px]  w-[70%]  text-[white] font-[900] capitalize tran">
          404 ! <br /> Page not found
          </h1>
        <Link
        to="/"
        className="
                bg-[#e91e63] h-[60px] flex flex-row items-center justify-center rounded-[5px] hover:rounded-[0px] transition-[0.9s]
                 p-[20px]
                "
        ><i class='bx bx-left-arrow-alt'></i>Back To Home Page</Link>
        </div>


      </body>
    </motion.ul>
    )
}

export default NoFound