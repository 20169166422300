import Header from "./Header";
import "./home.css";
import { motion } from "framer-motion";
import Call from "./Call";
function AboutUs() {
  const contactUs = [
    {
      id: 1,
      title: "E-mail",
      content: "pestargon@gmail.com",
      icon: "bx bx-location-plus",
    },
    {
      id: 2,
      title: "Telephone",
      content: "0708 336 4782",
      icon: "bx bx-phone",
    },
  ];

  const services = [
    {
      id: 1,
      title: "Cleaning Services",
      body: "At Jolayemi cleaning and pest control services, cleanliness is our passion. We understand that a clean space promotes health, productivity, and overall satisfaction. Our experienced team is dedicated to delivering exceptional cleaning services, whether it's for your home, office, or commercial facility.",
      content1: "Home Cleaning Services",
      content2: "Office Cleaning Services",
      content3: "Janitorial Services",
    },
    {
      id: 2,
      title: "Fumigation Services",
      body: "We offer various forms of pest control services and fumigation services in Lagos and Nigeria at Large, ranging from de-rating services, bed bug extermination, bat control, insect control/extermination etc. Call or text us now to book an appointment with our fumigation officers.",
      content1:
        "Home Fumigation Service in Lekki, Ajah, Ikeja, Yaba, Surulere, Ikorodu, Lagos Nigeria",
      content2:
        "Office Fumigation Service In Lekki, Ajah, Ikeja, Yaba, Surulere, Ikorodu, Lagos Nigeria",
      content3:
        "Pest Control Service in Lekki, Ajah, Ikeja, Yaba, Surulere, Ikorodu, Lagos Nigeria",
    },
  ];

  return (
    <motion.ul
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <Header />
      <Call />
      <body className=" flex flex-col items-center justify-center">
        <div className="aboutUsStarter w-full pt-[80px] pb-[80px] lg:h-[60vh] z-48 text-[white] flex flex-col items-center justify-center">
          <h1 className="text-[30px] lg:text-[50px] w-[80%] text-[white] font-[900] capitalize tran">
            About Us – Jolayemi cleaning and pest control services
          </h1>
          <a
            href="tel:07083364782"
            className="
                    bg-[#e91e63] h-[60px] w-[150px] flex flex-col items-center justify-center rounded-[5px] hover:rounded-[0px] transition-[0.9s]
                     p-[20px] mt-[30px]
                    "
          >
            Call Us
          </a>
        </div>

        <section className="homeBody pb-[30px] w-full flex flex-col items-center justify-center">
          <section className="flex relative mt-[-50px] flex-col bg-[white] z-50 w-[95%] p-[20px] rounded-t-[10px] items-center justify-center ">
            <img
            loading="lazy"
              src="/images/general.png"
              className="rounded-[5px] lg:w-[650px]"
              alt="company logo"
            />
            <p className="text-[20px] text-[#3c4858] lg:w-[70%] w-full pt-[30px] font-[500]">
              At Jolayemi Cleaning and Pest Control Services, we are a cleaning
              agency and understand the importance of a tidy workplace. Our
              company was founded on the principles of warm, friendly and efficient
              serivce. Our staffs know how to get the job done. We strive to
              excel in our field with our range of services as; cleaning,
              fumigation and interior designing. We believe that your needs can
              be fully met. No office or residential space is too big or small
              for our skilled and experienced staffs.
            </p>
          </section>
          <div className="flex z-50 lg:flex-row flex-col items-center justify-center h-[80px] rounded-b-[10px] text-[white] bg-[#3a3a3a] w-[95%]">
                        <h1 className="text-[15px] w-[80%] lg:text-[30px] lastText">We do our job with all sense of professional excellence and integrity !</h1>
                </div>     
        </section>
      </body>
    </motion.ul>
  );
}

export default AboutUs;
