import Header from "./Header"
import "./home.css"
import { motion } from "framer-motion"
import Call from "./Call"
function ContactUs(){
    const contactUs = [
        {
            id: 1,
            title: "Location",
            content: "Address; 15, Sule-Abore street ojodu-berger, Lagos state.",
            icon: 'bx bx-location-plus'
        },
        {
            id: 2,
            title: "E-mail",
            content: "Jolayemiservices@gmail.com",
            icon: 'bx bx-envelope'
        },
        {
            id: 3,
            title: "Telephone",
            content: "0708 336 4782, 0810 292 4562",
            icon: 'bx bx-phone'
        }
      ]

      
    return(
        <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        >
        <Header />
        <Call />
          <body className="   flex flex-col items-center justify-center">
            <div className="contactStarter w-full pt-[50px] lg:pt-[80px] pb-[80px] lg:h-[60vh] z-49 text-[white] flex flex-col items-center justify-center">
              <h1 className="text-[30px] lg:text-[50px] w-[80%] text-[white] font-[900] capitalize tran">
              Contact Us – Jolayemi cleaning and pest control services
              </h1>
              <a
                href="tel:07083364782"
                className="
                    bg-[#e91e63] h-[60px] w-[150px] flex flex-col items-center justify-center rounded-[5px] hover:rounded-[0px] transition-[0.9s]
                     p-[20px] mt-[30px]
                    "
              >
                Call Us
              </a>
            </div>

            <section className="homeBody  pb-[30px] w-full flex flex-col items-center justify-center">
    
            <section className="flex relative mt-[-50px] flex-col bg-[white] z-50 w-[95%] p-[20px] rounded-t-[10px] items-center justify-center ">

            <img 
            loading="lazy"
                src="/images/cleaning2.jpg"
                className="w-[650px]"
            />  
                                        <h1
                            className="text-[30px] font-[600] pt-[30px]"
                            >Contact Us</h1>
                            <section
                        className="text-left mt-[30px]  w-full flex flex-col lg:flex-row items-start justify-evenly"
                        >

                            
{/*     
                            {
                                contactUs.map((contact, index) => (
                                    <div
                                    key={index}
                                    className="flex flex-col "
                                    >
                                        <div
                                        className="text-[40px]"
                                        >
                                            <i class={contact.icon}></i>
                                        </div>
    
                                        <div
                                        className="text-left"
                                        >
                                            <h1 
                                            className="text-[20px] font-[100]"
                                            >{contact.title}</h1>
                                            <p
                                            className="text-[20px] font-[100]"
                                            >{contact.content}</p>
                                        </div>
                                    </div>
                                ))
                            } */}

                            <div className="flex flex-col">
                            <div
                            className="text-[40px]"
                            >
                            <i class='bx bx-location-plus'></i>
                            </div>
                                

                                <div
                                className="text-left"
                                >
                                    <h1
                                    className="text-[20px] font-[100]"
                                    >Location</h1>
                                    <p
                                    className="text-[20px] font-[100]"
                                    >15, Sule-Abore street ojodu-berger,<br 
                                        className="lg:flex hidden"
                                    /> Lagos state.</p>
                                </div>

                                
                            </div>

                            <div className="flex flex-col">
                            <div
                            className="text-[40px]"
                            >
                            <i class='bx bx-envelope'></i>
                            </div>
                                

                                <div
                                className="text-left"
                                >
                                    <h1
                                    className="text-[20px] font-[100]"
                                    >E-mail</h1>
                                    <a
                                    href="mailto:Jolayemiservices@gmail.com"
                                    className="text-[20px] font-[100] hover:text-[#e91e63]"
                                    >Jolayemiservices@gmail.com</a>
                                </div>

                                
                            </div>

                            <div className="flex flex-col">
                            <div
                            className="text-[40px]"
                            >
                            <i class='bx bx-phone'></i>
                            </div>
                                

                                <div
                                className="text-left"
                                >
                                    <h1
                                    className="text-[20px] font-[100]"
                                    >Telephone</h1>
                                    <div
                                    className="flex flex-col lg:flex-row items-left"
                                    >
                                    <a
                                    href="tel:07083364782"
                                    className="text-[20px] font-[100] hover:text-[#e91e63] pr-[0px] lg:pr-[5px]"
                                    >0708 336 4782,</a>
                                    <a
                                    href="tel:08102924562"
                                    className="text-[20px] font-[100] hover:text-[#e91e63]"
                                    >
                                    0810 292 4562
                                    </a>
                                    </div>

                                </div>

                                
                            </div>
                            


                            
                        </section>
                        <div
                            className="flex flex-col w-full text-left lg:justify-center lg:items-center justify-start items-start text-[40px] mt-[30px]"
                            >
                            <i class='bx bx-time pb-[20px]' ></i>
                            <h1 className=" text-[30px] font-[600]">Hours</h1>
                            <p className="text-[20px] font-[100]">Monday—Friday: 9:00AM–5:00PM <br/> Saturday & Sunday: 11:00AM–3:00PM</p>
                            </div>

    
    
    
            </section>
            <div className="flex z-50 lg:flex-row flex-col items-center justify-center h-[80px] rounded-b-[10px] text-[white] bg-[#3a3a3a] w-[95%]">
                        <h1 className="text-[15px] w-[80%] lg:text-[30px] lastText">We do our job with all sense of professional excellence and integrity !</h1>
                </div>     
            </section>
          </body>
        </motion.ul>
    )
}

export default ContactUs