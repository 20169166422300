import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import Header from './components/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactUs from './components/ContactUs';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import NoFound from './components/NoFound';

function App() {
  return (
    <div className="App">
    {/* <Header /> */}
    <BrowserRouter>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='*' element={<NoFound />}/>
        <Route path='/contactus' element={<ContactUs />}/>
        <Route path='/ourservices' element={<Services />} />
        <Route path='/aboutus' element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
