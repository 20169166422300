import "./home.css";
import Header from "./Header";
import { motion } from "framer-motion";
import { useState } from "react";
import Call from "./Call";

function Home({toggleSidebar}) {
  const service = [
    {
      id: 1,
      title: "Fumigation and Pest Control Services",
      body: "We offer various forms of pest control services and fumigation services in Lagos and Nigeria at Large, ranging from de-rating services, bed bug extermination, bat control, insect control/extermination etc. Call or text us now to book an appointment with our fumigation officers.",
    
    },
    {
      id: 2,
      title: "Cleaning Services, Interior Decoration and House Painting",
      body: "Our zero tolerance for unclean environments has made us make your home and offices hygiene our priority. Our services includes home organization, interior design, home cleaning, office cleaning, janitorial services etc. Call or text us now to book an appointment with us today.",
    },
  ];

  const specialities = [
    {
      id: 1,
      title: "Bedbug Control",
      body: "Bedbugs can be a nightmare for any business, especially in a sensitive industry like fertilization. These tiny pests can easily infest your premises and disrupt your operations. Our team of experts specializes in bedbug control, offering swift and effective solutions to eliminate these troublesome insects. With our services, you can rest assured that your facility will remain bedbug-free, ensuring the integrity of your properties.",
      image: "/images/bedbug.jpg"
    },
    {
      id: 2,
      title: "Rat Fumigation",
      body: "Rats can not only damage your property but also pose health risks through the spread of diseases. Our rat fumigation services are tailored to your unique needs, utilizing the latest pest control techniques to eliminate these rodents. We work discreetly and efficiently to ensure minimal disruption to your operations while effectively addressing the rat infestation.",
      image:  "/images/rat.jpg"
    },
    {
      id: 3,
      title: "Termite Control/Fumigation",
      body: "Termites pose a significant threat to the structural integrity of your facility, as they can cause extensive damage over time. Our termite control and fumigation services are designed to safeguard your property against these wood-destroying pests. We employ state-of-the-art techniques and environmentally friendly treatments to protect your investments, homes and maintain a secure environment.",
      image:  "/images/termite.png"
    },
    {
      id: 4,
      title: "Cockroach Fumigation",
      body: "Cockroaches are notorious carriers of disease and can contaminate your workspace. Our cockroach fumigation services target these pests at their source, eradicating them from your premises. We use safe and effective methods to ensure that your facility remains free from these health hazards, allowing you to have a clean and hygienic environment.",
      image:  "/images/roach.jpg"
    },
    {
        id: 5,
        title: 'Interior Designing',
        body: "Our team of skilled and creative interior designers specializes in transforming spaces into inviting, functional, and visually stunning environments. Whether you're looking to revamp your living room, create a productive office layout, or enhance the ambiance of your commercial space, we have the expertise to bring your vision to life.",
        image: "/images/interiorDes.jpeg"
    },
    {
        id: 6,
        title: "Cleaning Services",
        body: "At Jolayemi cleaning and pest control services, cleanliness is our passion. We understand that a clean space promotes health, productivity, and overall satisfaction. Our experienced team is dedicated to delivering exceptional cleaning services, whether it's for your home, office, or commercial facility. Our cleaning professionals are trained, skilled, and equipped with the latest cleaning technology and eco-friendly cleaning products. You can rely on us for efficient and thorough cleaning that meets the highest industry standards.",
        image: "/images/cleaningDes.jpg"
    },
    {
        id: 7,
        title: "House Painting",
        body: "House painting is a crucial aspect of home maintenance and improvement that involves applying paint to the interior or exterior surfaces of a house. It serves both aesthetic and functional purposes, contributing to the overall appearance and protection of the property. Our experienced team is dedicated to delivering exceptional house painting services. Our personnels are trained, skilled, and equipped with the latest house painting equipments.",
        image: "/images/painting.png"
    }
  ];

  const contactUs = [
    {
        id: 1,
        title: "Location:",
        content: "15, Sule-Abore street ojodu-berger, Lagos state.",
        icon: 'bx bx-location-plus'
    },
    {
        id: 2,
        title: "Email:",
        content: "Jolayemiservices@gmail.com",
        icon: 'bx bx-envelope'
    },
    {
        id: 3,
        title: "Telephone:",
        content: "0708 336 4782, 0810 292 4562",
        icon: 'bx bx-phone'
    }
  ]

  const [showSecondNav, setShowSecondNav] = useState(false);

  const switcher = () => {
    setShowSecondNav(false);
  };

  console.log(showSecondNav)
  return (
    <motion.ul 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
    >
    <Header isOpen={showSecondNav} />
    <Call />
      <body className=" flex flex-col items-center justify-center"
      onClick={switcher}
      >
        <div className="bodyStarter w-full pt-[50px] pb-[80px] lg:h-[70vh] z-48 text-[white] flex flex-col items-center justify-center">
          <h1 className="text-[40px] lg:text-[50px]  w-[70%]  text-[white] font-[900] capitalize tran">
            Trusted Fumigation, Cleaning, Interior decoration and house painting Company
          </h1>

          <p className="text-[25px] py-[20px]">0708 336 4782, 0810 292 4562</p>
          <a
            href="tel:07083364782"
            className="
                bg-[#e91e63] h-[60px] w-[150px] flex flex-col items-center justify-center rounded-[5px] hover:rounded-[0px] transition-[0.9s]
                 p-[20px]
                "
          >
            Call Us
          </a>
        </div>

        <section className="homeBody pb-[30px] flex flex-col items-center justify-center">


        <section className="flex relative mt-[-50px] flex-col bg-[white] z-50 w-[95%] p-[20px] rounded-t-[10px] items-center justify-center ">
          <h1 className="pt-[50px] text-[#3c4858] lg:pb-[100px] pb-[50px] text-[50px] capitalize">
            Our Services
          </h1>
          <section className="flex lg:flex-row flex-col items-start justify-center">
            {service.map((service, index) => (
              <div key={index} className="lg:w-[30%] pb-[30px] lg:pb-[0px] w-full lg:mx-[20px]">
                <h1 className="text-[20px] text-[#3c4858] font-[500]">
                  {service.title}
                </h1>
                <p className="text-[22px] text-[#999] font-[300] ">
                  {service.body}
                </p>
              </div>
            ))}


          </section>
          <h1 className="pt-[50px] pb-[30px] text-[#3c4858] text-[50px] capitalize">
              Some Of Our Specialties
            </h1>

            <p className="text-[#999] text-[25px] font-[300] lg:w-[60%] w-full">
              We offer various forms of fumigation services and pest control
              services in Nigeria, here are some pest problems that we have proven
              to have capable hands and equipment to effectively eliminate.
            </p>
                <section
                className="flex flex-row flex-wrap w-full"
                >
                {
                specialities.map((speciality, index) => (
                    <div key={index}
                    className="flex lg:flex-row flex-col mt-[80px] lg:w-[50%] w-full lg:items-start items-center justify-center"
                    >
                    <div>
                        <img 
                        loading="lazy"
                            src={speciality.image}
                            className="lg:w-[250px] w-full mr-[30px] shadow-xl"
                        />
                    </div>

                    <div
                    className="lg:w-[50%] w-full lg:text-left text-center lg:pt-[0px] pt-[20px]"
                    >
                        <h1 className="text-[20px] text-[#3c4858] font-[500]">{speciality.title}</h1>
                        <p className="text-[22px] text-[#999] font-[300]">{speciality.body}</p>
                    </div>
                    </div>
                ))
            }
                </section>




        </section>

        <section
            className="bodyFooter  z-50 w-[95%] h-full lg:px-[100px] px-[30px] py-[30px]"
            >

                <div
                className="flex lg:flex-row flex-col items-start justify-between text-[white]"
                >
                    <section
                    className="text-left w-full flex flex-col justify-start items-start"
                    >
                        <h1
                        className="text-[30px] font-[600]"
                        >Contact Us</h1>
                        

                        {
                            contactUs.map((contact, index) => (
                                <div
                                key={index}
                                className="flex flex-row my-[30px] lg:my-[50px]"
                                >
                                    <div
                                    className="text-[40px]"
                                    >
                                        <i class={contact.icon}></i>
                                    </div>

                                    <div
                                    className="text-left"
                                    >
                                        <h1 
                                        className="text-[30px] font-[600]"
                                        >{contact.title}</h1>
                                        <p
                                        className="text-[20px] font-[100]"
                                        >{contact.content}</p>
                                    </div>
                                </div>
                            ))
                        }

                        <h1 className="text-[30px] font-[600] ">Hours</h1>
                        <p className="text-[20px] font-[100]">Monday—Friday: 9:00AM–5:00PM <br/> Saturday & Sunday: 11:00AM–3:00PM</p>
                        
                    </section>

                    <section
                    className="bg-[#e91e63] mt-[20px] lg:mt-[0px] px-[20px] py-[40px] rounded-[10px] transition-[0.9s] hover:rounded-[0px]"
                    >
                        <h1 
                        className="text-[20px] font-[500]"
                        >For immediate response, please call: <br/> 07083364782 or 08102924562</h1>
                    </section>
                </div>


                </section>
            <div className="flex z-50 lg:flex-row flex-col items-center justify-center h-[80px] rounded-b-[10px] text-[white] bg-[#3a3a3a] w-[95%]">
                        <h1 className="text-[15px] w-[80%] lg:text-[30px] lastText">We do our job with all sense of professional excellence and integrity !</h1>
                </div>      
            </section>
      </body>
    </motion.ul>
  );
}

export default Home;
