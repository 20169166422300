import Header from "./Header";
import "./home.css";
import { motion } from "framer-motion";
import Call from "./Call";
function Services() {
  const contactUs = [
    {
      id: 1,
      title: "E-mail",
      content: "pestargon@gmail.com",
      icon: "bx bx-location-plus",
    },
    {
      id: 2,
      title: "Telephone",
      content: "0708 336 4782",
      icon: "bx bx-phone",
    },
  ];

  const services = [
    {
      id: 1,
      title: "Cleaning Services",
      body: "At Jolayemi cleaning and pest control services, cleanliness is our passion. We understand that a clean space promotes health, productivity, and overall satisfaction. Our experienced team is dedicated to delivering exceptional cleaning services, whether it's for your home, office, or commercial facility.",
      content1: "Home Cleaning Services in Nigeria",
      content2: "Office Cleaning Services in Nigeria",
      content3: "Janitorial Services in Nigeria",
    },
    {
      id: 2,
      title: "Fumigation Services",
      body: "We offer various forms of pest control services and fumigation services in Lagos and Nigeria at Large, ranging from de-rating services, bed bug extermination, bat control, insect control/extermination etc. Call or text us now to book an appointment with our fumigation officers.",
      content1: "Home Fumigation Service in Nigeria",
      content2: "Office Fumigation Service in Nigeria",
      content3: "Pest Control Service in Nigeria",
    },
  ];

  return (
    <motion.ul
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <Header />
      <Call />
      <body className=" flex flex-col items-center justify-center">
        <div className="servicesStarter w-full pt-[80px] pb-[80px] lg:h-[60vh] z-48 text-[white] flex flex-col items-center justify-center">
          <h1 className="text-[30px] lg:text-[50px] w-[80%] text-[white] font-[900] capitalize tran">
            Our Services – Jolayemi cleaning and pest control services
          </h1>
          <a
            href="tel:07083364782"
            className="
                    bg-[#e91e63] h-[60px] w-[150px] flex flex-col items-center justify-center rounded-[5px] hover:rounded-[0px] transition-[0.9s]
                     p-[20px] mt-[30px]
                    "
          >
            Call Us
          </a>
        </div>

        <section className="homeBody pb-[30px] w-full flex flex-col items-center justify-center">
          <section className="flex relative mt-[-50px] flex-col bg-[white] z-50 w-[95%] p-[20px] rounded-t-[10px] items-center justify-center ">
            <img loading="lazy" src="/images/clean3.jpg" className="w-[650px]" />
            <section className="text-left mt-[30px] lg:w-[70%]  w-full flex flex-col items-start justify-between">
              {services.map((service, index) => (
                <div key={index} className="flex flex-col ">
                  <h1 className="text-[20px] text-[#3c4858] font-[500] pt-[20px]">
                    {service.title}
                  </h1>
                  <h2 className="text-[18px] lg:text-[22px] text-[#999] font-[200]">
                    {service.body}
                  </h2>

                  <ul key={index} className="list-disc ml-[16px] pt-[15px]">
                    <li>
                      <span className="font-[500] text-[18px] lg:text-[20px]">
                        {service.content1.split(" ").slice(0, 3).join(" ")}{" "}
                      </span>
                      {service.content1.split(" ").slice(3).join(" ")}
                    </li>

                    <li>
                      <span className="font-[500] text-[18px] lg:text-[20px]">
                        {service.content2.split(" ").slice(0, 3).join(" ")}{" "}
                      </span>
                      {service.content2.split(" ").slice(3).join(" ")}
                    </li>

                    <li>
                      <span className="font-[500] text-[18px] lg:text-[20px]">
                        {service.content3.split(" ").slice(0, 3).join(" ")}{" "}
                      </span>
                      {service.content3.split(" ").slice(3).join(" ")}
                    </li>
                  </ul>
                </div>
              ))}

              <div className="flex flex-col ">
                <h1 className="text-[20px] text-[#3c4858] font-[500] pt-[20px]">
                  Interior Decoration
                </h1>
                <h2 className="text-[18px] lg:text-[22px] text-[#999] font-[200]">
                  Our team of skilled and creative interior designers
                  specializes in transforming spaces into inviting, functional,
                  and visually stunning environments. Whether you're looking to
                  revamp your living room, create a productive office layout, or
                  enhance the ambiance of your commercial space, we have the
                  expertise to bring your vision to life. Call or text us now to
                  book an appointment with us.
                </h2>
              </div>

              <div className="flex flex-col ">
                <h1 className="text-[20px] text-[#3c4858] font-[500] pt-[20px]">
                  House Painting
                </h1>
                <h2 className="text-[18px] lg:text-[22px] text-[#999] font-[200]">
                  House painting is a crucial aspect of home maintenance and
                  improvement that involves applying paint to the interior or
                  exterior surfaces of a house. It serves both aesthetic and
                  functional purposes, contributing to the overall appearance
                  and protection of the property. Our experienced team is
                  dedicated to delivering exceptional cleaning services, whether
                  it's for your home, office, or commercial facility. Our
                  personnels are trained, skilled, and equipped with the latest
                  house painting equipments.
                </h2>
              </div>
            </section>
          </section>
          <div className="flex z-50 lg:flex-row flex-col items-center justify-center h-[80px] rounded-b-[10px] text-[white] bg-[#3a3a3a] w-[95%]">
                        <h1 className="text-[15px] w-[80%] lg:text-[30px] lastText">We do our job with all sense of professional excellence and integrity !</h1>
                </div>     
        </section>
      </body>
    </motion.ul>
  );
}

export default Services;
